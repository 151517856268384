import {PiCirclesThreeThin} from "react-icons/pi";

export default function TermsPage() {
    return (
        <div className={' md:px-10 md:py-5'}>
            <div className="w-full p-5 rounded-2xl justify-between  flex flex-col md:flex-row items-center bg-white">
                <div className=" p-5 flex pt-10 flex-col gap-3">
                    <div className="p-2 py-3 bg-[#FFF3FA] font-bold text-[#ED5E93] flex flex-row gap-2 w-56 items-center justify-center rounded-full">
                        <PiCirclesThreeThin className={'rotate-45'} />
                        Terms and Conditions
                    </div>
                    <h1 className={'font-bold text-3xl md:text-4xl'}>Stage One Terms and Conditions</h1>
                    <div className="flex flex-col gap-4 text-lg md:text-xl">
                        <h2>Venue Usage</h2>
                        <p>
                            By booking an event at Stage One, you agree to abide by the rules and regulations set forth by the management. The venue is to be used solely for the purpose specified in the booking agreement, including press conferences, comedy shows, production activations, book launches, small concerts, and similar events.
                        </p>

                        <h2>Booking and Payment</h2>
                        <p>
                            All bookings must be made in advance through the Stage One online, USSD ticketing platform, ISHUSHO MoMo code, or by check. A non-refundable 50% deposit may be required to secure the booking, with the remaining balance due before the event date.
                        </p>

                        <h2>Cancellation Policy</h2>
                        <p>
                            In the event of cancellation, any deposits or payments made are non-refundable unless otherwise stated in the booking agreement. Cancellations must be made in writing and received by ISHUSHO Ltd management within the specified time frame outlined in the booking agreement.
                        </p>

                        <h2>Event Promotion</h2>
                        <p>
                            Every event scheduled at Stage One will be broadcast live on ISHUSHO TV and streamed on client platforms unless otherwise requested by the organizer. By booking an event at Stage One, you consent to your event's live broadcast and streaming.
                        </p>

                        <h2>Ticketing Services</h2>
                        <p>
                            Stage One provides online and USSD ticketing services to facilitate ticket sales for events hosted at the venue and elsewhere. Event organizers are responsible for setting ticket prices, managing ticket inventory, and promoting ticket sales through their preferred channels.
                        </p>

                        <h2>Multimedia Services</h2>
                        <p>
                            As part of our commitment to enhancing the event experience, Stage One offers multimedia services such as sound, lighting, and audiovisual equipment. Additional fees may apply for in case the client needs extra equipment to meet their expectation, and arrangements must be made in advance.
                        </p>

                        <h2>Liability</h2>
                        <p>
                            While every effort is made to ensure the safety and security of all attendees, Stage One and its affiliates cannot be held liable for any accidents, injuries, or damages that may occur during events held at the venue. Event organizers are responsible for obtaining appropriate insurance coverage for their events.
                        </p>

                        <h2>Changes to Terms and Conditions</h2>
                        <p>
                            Stage One reserves the right to amend or update these Terms and Conditions at any time without prior notice. It is the responsibility of event organizers to review the Terms and Conditions periodically for any changes or updates.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}