import {PiCirclesThreeThin, PiXLogo} from "react-icons/pi";
import { FaPhoneAlt, FaBell } from "react-icons/fa";
import {IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin} from "react-icons/io5";

export default function AboutPage() {
    return (
        <div className={' md:px-10 md:py-5'}>
            <div className="absolute hidden md:block w-1/4 h-full bg-gray-200 left-0 top-0 -z-10"></div>
            <div className="w-full p-5 rounded-2xl justify-between  flex flex-col md:flex-row items-center bg-white">
                <div className=" p-5 flex pt-10 flex-col gap-3">
                    <div className="p-2 py-3 bg-[#FFF3FA] font-bold text-[#ED5E93] flex flex-row gap-2 w-32 items-center justify-center rounded-full">
                        <PiCirclesThreeThin className={'rotate-45'} />
                        About Us
                    </div>
                    <h1 className={'font-bold text-3xl md:text-4xl'}>Welcome to Stage One</h1>
                    <div className="flex flex-col gap-4 text-lg md:text-xl">
                        <p>Your premier destination for small-scale events with big impact.</p>
                        <p>
                            At Stage One, we offer a versatile event space designed to cater to a wide range of
                            occasions, including press conferences, comedy shows, production activations, book launches,
                            small concerts, and more. Our venue is meticulously crafted to provide a dynamic and
                            engaging atmosphere, ensuring that every event hosted here leaves a lasting impression.
                        </p>
                        <p>
                            What sets Stage One apart is our commitment to innovation and convenience. Not only do we
                            provide a state-of-the-art event space, but we also offer <span className="highlight">online and USSD ticketing services</span> to
                            streamline the event planning process for organizers. With our all-in-one platform, event
                            organizers can seamlessly manage their events, from venue booking to ticket sales, all while
                            enjoying multimedia services to enhance their event experience.
                        </p>
                        <p>
                            Additionally, every event scheduled at Stage One is broadcast live on <span
                            className="highlight">ISHUSHO TV</span> and streamed on our client platforms, reaching a
                            wider audience and maximizing exposure for our partners.
                        </p>
                        <p>
                            Whether you're a seasoned event organizer or hosting your first event, Stage One is here to
                            support you every step of the way. Join us at Stage One and let's create unforgettable
                            experiences together.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}