import { IoLogoInstagram, IoLogoLinkedin, IoLogoFacebook } from "react-icons/io5";
import { PiXLogo } from "react-icons/pi";
import logo from '../assets/images/logo-ishusho.png';
import {Link} from "react-router-dom";

export default function MainFooter() {
    return (
        <footer className={'w-full bg-darker text-white p-10'}>
            <div className="grid-cols-1 md:grid-cols-3 grid gap-4 border-b-2 pb-8 border-white/20">
                <div className="w-full">
                    <div className="flex flex-row items-center">
                        <p className={'w-20'}>Email Us</p>
                        <div className="h-[1px] w-full bg-white/20"></div>
                    </div>
                    <div className="mt-5 text-gray-300">stageonerwanda@gmail.com</div>
                </div>

                <div className="w-full">
                    <div className="flex flex-row items-center">
                        <p className={'w-20'}>Call Us</p>
                        <div className="h-[1px] w-full bg-white/20"></div>
                    </div>
                    <div className="mt-5 text-gray-300">+250 789 010 896
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex flex-row items-center">
                        <p className={'w-20'}>Find Us</p>
                        <div className="h-[1px] w-full bg-white/20"></div>
                    </div>
                    <div className="mt-5 flex flex-row gap-5 justify-start text-xl text-gray-300">
                        <a target={'_blank'} href="https://www.instagram.com/stageonerw/?utm_source=ig_web_button_share_sheet" rel="noreferrer">
                            <IoLogoInstagram/>
                        </a>
                        <a target={'_blank'} href="https://x.com/stageonerw?s=21" rel="noreferrer">
                            <PiXLogo/>
                        </a>
                        <IoLogoFacebook/>
                        <IoLogoLinkedin/>
                    </div>
                </div>
            </div>

            <div className="grid-cols-1 mt-5 md:grid-cols-3 grid gap-4 border-b-2 pb-8 border-white/20">
                <div className="w-full">
                    <div className="flex flex-col items-start gap-2 py-2">
                        <img src={logo} alt={'ISHEMA TICKETS'} className={'w-14'}/>
                        <p className={'text-gray-300'}>Nyarugenge, KN 1 ST</p>
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex flex-col items-start gap-2 py-2">
                        <p className={'w-20'}>Quick Links</p>
                        <div className="flex flex-wrap w-full">
                            <Link to={'/tickets'} className={'text-gray-300 w-1/2'}>Tickets</Link>
                            <Link to={'/events'} className={'text-gray-300 w-1/2'}>Discover</Link>
                            <Link to={'/about-us'} className={'text-gray-300 w-1/2'}>About Us</Link>
                            <Link to={'/contact-us'} className={'text-gray-300 w-1/2'}>Contact Us</Link>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex flex-col items-start gap-2 py-2">
                        <p className={'w-28'}>Get Support</p>
                        <div className="flex flex-wrap w-full">
                            <Link to={''} className={'text-gray-300 w-1/2'}>Help</Link>
                            <Link to={''} className={'text-gray-300 w-1/2'}>Our Work</Link>
                            <Link to={''} className={'text-gray-300 w-1/2'}>Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                @ Ishusho tickets 2024
            </div>
        </footer>
    );
}
