import toast from 'react-hot-toast';
import { storage } from './storage';
import { useEffect } from "react";
import {useWhoamiQuery} from "../api/auth";
import {useNavigate} from "react-router-dom";

export default function useCheckUser() {
    if (storage.getToken() == null) {
        storage.removeToken();
        window.location.href = '/';
        toast.error('Please Login Again');
    }

    const check = () => {
        return storage.getToken() != null;
    }

    useEffect(() => {
        if(!check()) {
            storage.removeToken();
            window.location.href = '/login';
            toast.error('Please Login Again');
        }
    }, []);

    return check();
}

export const useVerifyAuth = () => {
    const {data, isLoading, isError } = useWhoamiQuery({});
    const navigate = useNavigate();

    useEffect(() => {
        if(isError) {
            navigate('/login')
        }
    }, [isError]);

    return {
        data,
        isLoading,
    };

}
