import { useRoutes } from "react-router-dom";
import {HomePage} from "./pages/home";
import HomeContainer from "./container/homeContainer";
import NotFoundPage from "./pages/special/NotFound";
import EventDetails from "./pages/event_details";
import ContactPage from "./pages/contact";
import Login from "./pages/auth/login";
import MyTickets from "./pages/tickets";
import Signup from "./pages/auth/signup";
import AllEvents from "./pages/all";
import AboutPage from "./pages/about";
import TermsPage from "./pages/termsNconditions";
import ProtectedContainer from "./container/ProtectedContainer";
import MySingleTicket from "./pages/ticket";

export default function AppRouter() {


    return useRoutes([
        {
            path: '/',
            element: <HomeContainer />,
            children: [
                { index: true, element: <HomePage /> },
                { path: '/login', element: <Login /> },
                { path: '/signup', element: <Signup /> },
                { path: '/event/:slug', element: <EventDetails />},
                { path: '/contact-us', element: <ContactPage /> },
                { path: '/about-us', element: <AboutPage /> },
                { path: '/terms-and-conditions', element: <TermsPage /> },
                { path: '/ticket/:id', element: <MySingleTicket /> },
                {
                    path: '/tickets',
                    element: <ProtectedContainer>
                        <MyTickets />
                    </ProtectedContainer> },
                { path: '/events', element: <AllEvents /> },
                { path: '*', element: <NotFoundPage /> }
            ]
        }
    ])
};