import {PiCirclesThreeThin, PiXLogo} from "react-icons/pi";
import { FaPhoneAlt, FaBell } from "react-icons/fa";
import {IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin} from "react-icons/io5";

export default function ContactPage() {
    return (
        <div className={' md:px-10 md:py-5'}>
            <div className="absolute hidden md:block w-1/4 h-full bg-gray-200 left-0 top-0 -z-10"></div>
            <div className="w-full p-5 rounded-2xl justify-between  flex flex-col md:flex-row items-center bg-white">
                <div className="md:w-1/2 p-5 flex pt-10 max-w-[600px] flex-col gap-3">
                    <div className="p-2 py-3 bg-[#FFF3FA] font-bold text-[#ED5E93] flex flex-row gap-2 w-32 items-center justify-center rounded-full">
                        <PiCirclesThreeThin className={'rotate-45'} />
                        Contact Us
                    </div>
                    <h1 className={'font-bold text-3xl md:text-4xl'}>Get in Touch: We're Here to Help!"</h1>
                    <div className="flex flex-col gap-4 text-lg md:text-xl">
                        <div className={'font-semibold flex flex-row items-center gap-4'}>
                            <FaPhoneAlt className={'text-xl'}/>
                            If you have an urgent business concern, please contact us at +25078**456
                        </div>
                        <div className={'font-semibold flex flex-row items-center gap-4'}>
                            <FaBell className={'text-xl'}/>
                            If you have an urgent business concern, please contact us at +25078**456
                        </div>
                        <div className="mt-5 flex flex-row gap-5 justify-start text-xl ">
                            <a target={'_blank'}
                               href="https://www.instagram.com/stageonerw/?utm_source=ig_web_button_share_sheet"
                               rel="noreferrer">
                                <IoLogoInstagram/>
                            </a>
                            <a target={'_blank'} href="https://x.com/stageonerw?s=21" rel="noreferrer">
                                <PiXLogo/>
                            </a>
                            <IoLogoFacebook/>
                            <IoLogoLinkedin/>
                        </div>
                    </div>
                </div>

                <form className="flex w-full md:w-1/2 p-5 items-start flex-col justify-start gap-3">
                    <input type={'text'} className={'bg-gray-100 px-5 py-3 w-full focus:outline-none rounded'}
                           name={'name'}
                           placeholder={'Names'}/>
                    <input type={'email'} className={'bg-gray-100 px-5 py-3 w-full focus:outline-none rounded'}
                           name={'name'}
                           placeholder={'Email'}/>
                    <textarea className={'bg-gray-100 h-36 px-5 py-3 w-full focus:outline-none rounded'} name={'name'}
                              placeholder={'Message'}/>
                    <input type={'button'} value={'Send Message'} className={'bg-darker text-white px-5 py-3 w-full focus:outline-none rounded'} name={'name'}
                           placeholder={'Enter Your Names'}/>
                </form>

            </div>
        </div>
    )
}